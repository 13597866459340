new WOW({ offset: 70 }).init();
function loadSliders() {
	$('.photo-gallery__images').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 4,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]

	});
}

var sliderScript = document.getElementById("deferredSlickScript");

if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
}

$(document).ready(function () {

	if ($("#BannerPanel").length !== 0) {
		$(".category-links__link").addClass("wow fadeInUp");
		$("#LeftBlock").addClass("wow bounceIn");
	}
	if (window.matchMedia('(min-width:768px)').matches) {
		$("#Logo").addClass("wow fadeIn");
	} else {
		$("#Logo").css("visibility", "visible");
	}

	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('#TopLinksInner').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		window.scrollBy(0, 1);
	});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('#TopLinksInner').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	});

	$("#TopLinksInner .dropdown-menu a").each(function (index, value) {
		$(this).attr("data-wow-delay", index / 10 + "s");
		$(this).attr("data-wow-offset", 0);
		$(this).addClass("wow fadeIn");
	});

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$("#HomepageGallery .Previous").click(function (e) {
		var img = $("#HomepageGalleryInner div").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$("#HomepageGalleryInner").append(img);
			img.width(170);
		});
		e.preventDefault();
		return false;
	});

	$("#HomepageGallery .Next").click(function (e) {
		var img = $("#HomepageGalleryInner div").last();
		img.width(0);
		img.remove();
		$("#HomepageGalleryInner").prepend(img);
		img.animate({ width: 170 }, 1000, "linear");
		e.preventDefault();
		return false;
	});

});
